.PageHeader {
    padding: 16px 24px 16px 0;
    .ant-page-header-heading-title {
        font-size: 32px;
        color: var(--app-text-color);
    }

    .ant-page-header-heading-title {
        font-weight: 400;
        text-transform: capitalize;
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;