.LandingPage {
  .mobileNavBar {
    display: none;
  }
  .Sidebar {
    .DBLogo {
      cursor: pointer;
    }
    .ant-layout-sider-children {
      .ant-menu {
        position: absolute;
        bottom: 10px;
        width: 100%;
        border-right: none;
        .ant-menu-item.homeButton {
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            .anticon {
              min-width: 32px;
              font-size: 20px;
              margin-right: 10px;
            }
            span {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  .LandingPageLayout {
    background-color: var(--app-background-color);
    position: relative;
    .homeButton {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #262d46;
    }
    .LandingPageContent {
      margin: 168px 0 0 71px;
      position: relative;
      .title {
        color: var(--app-text-color);
        font-size: 48px;
        margin-bottom: 1rem;
        font-weight: var(--app-main-header-font-weight);
        -webkit-font-weight: var(--app-main-header-font-weight);
        -moz-font-weight: var(--app-main-header-font-weight);
      }
      .description {
        color: var(--app-text-color);
        font-size: 23px;
        font-weight: var(--app-secondary-header-font-weight);
        -webkit-font-weight: var(--app-secondary-header-font-weight);
        -moz-font-weight: var(--app-secondary-header-font-weight);
        margin: 0;
        font-size: 1.2rem;
      }
      .LoginContainer {
        color: var(--app-text-color);
        background-color: #262d46;
        max-width: 650px;
        margin-top: 1rem;
        border-radius: 20px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        p {
          color: var(--app-text-color);
          align-self: flex-start;
          font-weight: var(--app-descriptions-font-weight);
          -webkit-font-weight: var(--app-descriptions-font-weight);
          -moz-font-weight: var(--app-descriptions-font-weight);
        }
        .boxTitle {
          color: var(--app-text-color);
          align-self: flex-start;
          font-size: 1.4rem;
          margin-bottom: 0.8rem;
          font-weight: var(--app-secondary-header-font-weight);
        }
        .logosContainer {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          .appleLogo {
            width: 150px;
          }
          .androidLogo {
            width: 186px;
          }
        }
      }
      .image {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .right {
        margin-left: 1rem;
      }
      .button {
        background-color: var(--app-secondary-color);
        height: 2.8rem;
        width: 280px;
        margin-top: 1rem;
        border-radius: 10px;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .LandingPage {
    height: 100vh;
    .mobileNavBar {
      background-color: #262d46;
      height: 80px !important;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      z-index: 1500;
      .mobileImage {
        width: 250px;
        cursor: pointer;
      }
    }
    .Sidebar {
      display: none;
    }
    .LandingPageLayout {
      .image {
        width: 500px !important;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .LandingPage {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    .mobileNavBar {
      background-color: #262d46;
      height: 100px !important;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      z-index: 1500;
      .mobileImage {
        width: 250px;
        cursor: pointer;
      }
    }
    .Sidebar {
      display: none;
    }
    .LandingPageLayout {
      height: 100% !important;
      width: 100% !important;
      position: relative;
      .LandingPageContent {
        margin: 0;
        padding: 100px 1rem 0.5rem 1rem;
        .image {
          display: none;
        }
        .title {
          font-size: 3rem;
          text-align: center;
        }
        .description {
          font-size: 1.5rem;
          .lineDivisor {
            display: none;
          }
        }
        .LoginContainer {
          display: block;
          position: relative;
          h2 {
            font-size: 1.2rem;
            text-align: center;
          }
          p {
            font-size: 0.9rem;
          }
          .buttonsContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: block;
            .right {
              margin: 1rem 0;
            }
            button {
              display: block;
              width: 100% !important;
            }
          }
          .logosContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: block;
            margin-top: 1.5rem;
            .androidLink {
              display: flex;
              margin-top: 1rem;
              justify-content: center;
              align-items: center;
              .androidLogo {
                width: 175px;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;