.UserSettingsModal {
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.UserSettingsModalError {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    font-size: 20px;
    margin: 1rem 0;
  }
  .subtitle {
    font-size: 16px;
    margin: 0.5rem 0 2rem 0;
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;