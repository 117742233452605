.GroupsTable {
    .ant-dropdown {
        .ant-table-filter-dropdown {
            .ant-dropdown-menu {
                .ant-dropdown-menu-item {
                    color: white;
                    &:hover {
                        color: black;
                    }
                    &-selected {
                        color: black;
                    }
                }
            }
            .ant-table-filter-dropdown-btns {
                .ant-btn-link:disabled {
                    color: var(--app-secondary-text-color);
                }
            }
        }
    }
    .ant-table-tbody {
        color: var(--app-text-color);
        .ant-table-column-sort {
            background-color: var(--app-sorted-column);
        }
    }
    .ant-empty-description {
        color: var(--app-text-color);
    }
    .ant-spin-nested-loading {
        .ant-spin-container {
            .ant-pagination.ant-table-pagination {
                color: var(--app-pagination-text-color);
                .ant-pagination-item > a {
                    color: inherit;
                }
                .ant-pagination-item-ellipsis {
                    color: var(--app-pagination-text-color);
                }
                .ant-pagination-item-link {
                    color: var(--app-pagination-text-color);
                }
            }
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;