.DBButton {
    width: 100%;
    border: none;
    color: var(--app-text-color);
    font-size: var(--app-button-font-size);
    border: 1px solid var(--app-secondary-text-color);

    & > span {
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:focus {
        color: var(--app-text-color);
    }
}

.create {
    color: var(--app-text-black) !important;
    background-color: var(--app-button-create-bg-color);
    &:hover {
        color: var(--app-text-black);
        background-color: var(--app-button-create-bg-color);
    }
    &:focus {
        color: var(--app-text-black);
        background-color: var(--app-button-create-bg-color);
    }
}

.delete {
    background-color: var(--app-button-cancel-bg-color);
    &:hover {
        color: var(--app-text-black);
        background-color: var(--app-button-cancel-bg-color);
        border: 1px solid var(--app-button-cancel-bg-color);
    }
}

.cancel {
    color: var(--app-text-black);
    background-color: var(--app-modal-background-color);
    font-size: 14px;
    &:focus {
        color: var(--app-text-black);
        background-color: var(--app-modal-background-color);
        border: 1px solid var(--app-secondary-text-color);
    }
    &:hover {
        color: var(--app-text-black);
        background-color: var(--app-secondary-text-color);
        border: 1px solid var(--app-secondary-text-color);
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;