span.badge {
    background: rgb(226, 226, 226);
    color: gray;
    font-family: monospace;
    margin-left: 4px;
    border-radius: 2px;
    padding: 1px;
}
a.link {
    display: inline;
}
.link {
    margin-top: 8px;
    color: var(--app-modal-label-text-color);
    display: inline-block;
}
a.link:hover {
    color: var(--app-modal-label-text-color);
    text-decoration: underline;
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;