.DBDashboard {
    background-color: #131626;

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border: none;
    }

    .trialVersionText {
        color: #75e6a8;
        position: absolute;
        z-index: 150;
        top: 10px;
        right: 15px;
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;