.trialExpiredModal {
  .ant-modal-content {
    .ant-modal-body {
      text-align: center;
      .title {
        color: white;
      }
      .content {
        color: white;
        margin-bottom: 2rem;
      }
    }
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;