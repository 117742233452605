.PendingRequestsModal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.PendingRequestModalDecision {
    width: 50%;
    min-height: 550px;
    padding: 24px;
    .radioGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
            & > .ant-radio-button-wrapper {
                width: 50%;
                text-align: center;
            }
            margin-bottom: 12px;
        }
        .ant-radio-button-wrapper {
            background-color: var(--app-modal-background-color);
        }
        .ant-radio-button-wrapper-checked {
            background-color: var(--app-secondary-color);
        }
        & > label {
            flex: 1;
            text-align: center;
        }
    }
    .commentTextArea {
        .ant-form-item-label {
            label {
                color: inherit;
            }
        }
    }
    .questionsList {
        margin: 20px 0px;
        & > li {
            margin-top: 0.5rem;
        }
    }
    .inputTextArea {
        max-height: 152px;
        background-color: var(--app-modal-background-color);
        margin-bottom: 1rem;
    }
    .ant-row {
        margin: 20px 0;
        margin-top: 20px;
        margin-bottom: 0px;
        .ant-col {
            .maintext {
                color: var(--app-modal-label-text-color);
            }
            .subtext {
                color: var(--app-form-label-text-color);
                font-size: 14px;
            }
        }
    }
    .requestModalApproverSelect {
        color: black;
        .ant-select-selection-item {
            color: var(--app-form-label-text-color);
        }
        .ant-select-selector {
            border-color: unset;
            background-color: unset;
            border: unset;
            margin: 0;
            height: fit-content;
        }
        width: 100%;
        background: #ffffff;
        border: 2px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 4px;
        // padding: 8px 13px;
        &:focus,
        &:focus-within,
        &:focus-visible {
            border: 2px solid #75e6a8;
            outline: none;
            box-shadow: rgba(66, 225, 127, 0.6) 0px 0px 0px 3px;
        }
    }
    .flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .whiteBtn {
        background-color: #d7d7d7;
        border: none;
        color: black !important;
        margin-left: 4px;
        svg {
            fill: black;
        }
        transform: scale(0.9);
        &:hover {
            transform: scale(1);
        }
    }
}
// @NOTE: this could be turned into its own component like DBSelect
// (just with a white select menu options)
.DBSelectDropdownRequestScreen {
    background-color: white !important;
    width: 80%;

    .rc-virtual-list {
        .rc-virtual-list-holder {
            .rc-virtual-list-holder-inner {
                .ant-select-item {
                    background-color: white;
                    color: var(--app-text-black);
                    &:hover {
                        background-color: #e9e9e9;
                    }
                }
                .ant-select-item-option-selected {
                    background-color: #75e6a8;
                    color: var(--app-text-black);
                }
                .ant-select-item-option-active {
                    background-color: #bceed2;
                    color: var(--app-text-black);
                }
                .ant-select-item-option-disabled {
                    color: var(--app-modal-label-text-color);
                    text-decoration-line: line-through;
                }
            }
        }
    }
    .ant-select-open .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
}

.PendingRequestModalInfo {
    width: 50%;
    height: 590px;
    padding: 24px;
    background-color: var(--app-modal-pending-request-bg-color);
    overflow-y: scroll;
    .ant-alert-message {
        color: var(--app-text-black);
    }
    .ant-row {
        margin: 20px 0;
        .ant-col {
            .buy {
                color: var(--app-modal-buy-color);
            }
            .sell {
                color: var(--app-modal-sell-color);
            }
            .maintext {
                color: var(--app-modal-label-text-color);
            }
            .subtext {
                color: var(--app-form-label-text-color);
                font-size: 14px;
            }
            .complementaryText {
                color: var(--app-form-label-text-color);
                font-size: 14px;
            }
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;