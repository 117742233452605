.RestrictionsSummaryContainer {
    padding: 1rem;
    background-color: var(--app-primary-color);
    min-height: 510px;
    .ant-table-container {
        .ant-table-content {
            .ant-table-tbody {
                .ant-table-row {
                    .ant-table-cell.ant-table-cell-ellipsis {
                        color: white;
                    }
                }
            }
        }
    }
    .RestrictionsSummaryHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            color: var(--app-text-color);
        }
        a {
            font-size: 14px;
        }
    }
    .ant-empty-description {
        color: var(--app-text-color);
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;