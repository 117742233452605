.ViewApprovalGroupModal {
    .manager-info-container {
        display: flex;
        align-items: center;
        border: 1px solid #cdcbcb;
        padding: 0.4rem 0.6rem;
        border-radius: 10px;
        margin: 0.5rem 0.5rem 0.5rem 0;
        .manager-info-description {
            margin-left: 1rem;
        }
    }
    .managers-view {
        height: 400px;
        overflow: scroll;
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;