.DBTableText.maintext {
    color: var(--app-text-color);
}

.DBTableText.subtext {
    font-size: var(--app-secondary-text-font-size);
    color: var(--app-secondary-text-color);
}
.DBTableText.exemptionColor {
    font-size: var(--app-secondary-text-font-size);
    color: #fadb5f;
}
.DBTableText.tradeColor {
    font-size: var(--app-secondary-text-font-size);
    color: #75e6a8;
}
.DBTableText.complementaryText {
    font-size: var(--app-secondary-text-font-size);
    color: var(--app-secondary-text-color);
}
.DBTableText.subtextLineThrough {
    text-decoration: line-through;
}
.DBTableText.capitalize {
    text-transform: capitalize;
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;