.EmailVerificationForm {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-form-background);
    .wave {
        position: fixed;
        bottom: 0;
    }
    .backButton {
        position: absolute;
        top: 10px;
        left: 10px;
        color: var(--app-primary-color);
    }
    .FormContainer {
        display: flex;
        z-index: 150;
        background-color: var(--app-text-color);
        border-radius: 10px;
        position: relative;
        max-width: 1100px;
        min-height: 815px;
        .ant-form {
            flex: 1;
            padding: 1.5rem;
            .ant-form-item-label {
                label {
                    color: var(--app-form-label-text-color);
                    font-size: 16px;
                    font-weight: var(--app-descriptions-font-weight);
                }
            }
            .formTitle {
                color: var(--app-primary-color);
                font-size: 1.5rem;
                margin-bottom: 2rem;
                text-align: center;
                font-weight: var(--app-secondary-header-font-weight);
            }

            .ant-form-item-control-input {
                .ant-input {
                    background-color: var(--app-modal-background-color);
                    height: 48px;
                }
            }

            .buttonsContainer {
                display: flex;
                margin-top: 4rem;
                .input-btn {
                    color: var(--app-text-black) !important;
                    background-color: var(--app-button-create-bg-color);
                    &:hover {
                        color: var(--app-text-black);
                        background-color: var(--app-button-create-bg-color);
                    }
                    &:disabled {
                        background-color: var(--app-button-disabled-bg-color);
                        text-shadow: none;
                        box-shadow: none;
                        cursor: not-allowed;
                    }
                }
            }
        }
        .rightForm {
            flex: 1;
            position: relative;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: var(--app-primary-color);
            color: var(--app-text-color);
            border-radius: 0 10px 10px 0;
            .DBImage {
                width: 250px;
                margin: 2rem 0;
            }
            .formTitleRight {
                color: var(--app-secondary-color);
                font-size: 1.5rem;
                margin-bottom: 2rem;
                font-weight: var(--app-seconday-header-font-weight);
            }
            .descriptionContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 2rem 4rem 2rem;
                .row {
                    display: flex;
                    margin-bottom: 1rem;
                    .icon {
                        color: var(--app-secondary-color);
                        font-size: 1.6rem;
                    }
                    .description {
                        display: inline-block;
                        margin-left: 1.2rem;
                        font-size: 1.2rem;
                        letter-spacing: 1px;
                        font-weight: var(--app-descriptions-font-weight);
                    }
                }
            }
            .bottomText {
                color: var(--app-secondary-color);
                font-weight: var(--app-descriptions-font-weight);
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .FormContainer {
        margin: 1rem;
    }
}

@media screen and (max-width: 1025px) {
    .FormContainer {
        .rightForm {
            .DBImage {
                width: 200px !important;
            }
            .formTitleRight {
                font-size: 1.2rem !important;
            }
            .descriptionContainer {
                .row {
                    .description {
                        font-size: 1rem !important;
                    }
                }
            }
        }
        .ant-form {
            .ant-form-item-label {
                label {
                    font-size: 1rem !important;
                }
            }
            .formTitle {
                font-size: 1.2rem !important;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .EmailVerificationForm {
        height: 100% !important;
        .wave {
            display: none;
        }
        .FormContainer {
            flex-direction: column;
            background-color: transparent;
            .ant-form {
                background-color: var(--app-text-color);
                border-radius: 10px;
            }
            .rightForm {
                margin-top: 2rem;
                border-radius: 10px;
            }
        }

        .backButton {
            display: none;
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;