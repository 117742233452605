.EditRestrictionModal {
  .ant-form {
    .ant-form-item-label {
      label {
        color: var(--app-form-label-text-color);
        font-size: 16px;
      }
    }

    .ant-form-item-control-input {
      .ant-input {
        background-color: var(--app-modal-background-color);
        height: 48px;
      }
    }

    .buttonsContainer {
      display: flex;
    }
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;