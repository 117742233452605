.DBCalendar {
  .react-calendar {
    color: white;
    padding: 1rem;
    width: 100%;
    font-family: inherit;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--app-primary-color);

    abbr[title],
    abbr[data-original-title] {
      text-decoration: none;
      cursor: inherit;
    }
    .whiteDay {
      color: white;
    }

    .react-calendar__tile--hover {
      background-color: inherit;
    }

    .react-calendar__tile:hover {
      background-color: var(--app-secondary-color);
    }

    .react-calendar__tile--now {
      background-color: var(--app-primary-color);
      border: 1px solid var(--app-secondary-color);
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }

    .react-calendar__tile--hoverStart,
    .react-calendar__tile--range {
      color: var(--app-text-black);
      background-color: var(--app-secondary-color);
    }

    .react-calendar__tile:disabled {
      background-color: var(--app-primary-color);
      color: #72606c;
    }

    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
      color: var(--app-text-black);
      background-color: var(--app-secondary-color);
      &:focus {
        color: var(--app-text-black);
        background-color: var(--app-secondary-color);
      }
      &:hover {
        color: var(--app-text-black);
        background-color: var(--app-secondary-color);
      }
    }

    .react-calendar__navigation button[disabled] {
      background-color: var(--app-primary-color);
    }

    .react-calendar__navigation__label:hover,
    .react-calendar__navigation__label:focus {
      background-color: var(--app-primary-color);
    }
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;