@import '~antd/lib/style/themes/default.less';

html {
    // Font Family
    --app-font-family: 'Inter', sans-serif;

    // Drawbridge main variables
    --app-primary-color: #262d46;
    --app-secondary-color: #75e6a8;
    --app-background-color: #131626;
    --app-text-color: #ffffff;
    --app-text-black: #000000;
    --app-secondary-text-color: #aea4ab;
    --app-main-text-font-size: 16px;
    --app-secondary-text-font-size: 12px;
    --app-main-header-font-weight: 700;
    --app-secondary-header-font-weight: 400;
    --app-descriptions-font-weight: 300;

    // Popup
    --app-popup-text-color: #cccccc;
    --app-popup-bg-color: #2e2e2e;

    // Pagination
    --app-pagination-text-color: #cccccc;

    // Modal
    --app-modal-background-color: #ffffff;
    --app-modal-description-color: #000000;
    --app-modal-label-text-color: #7b8794;
    --app-modal-pending-request-bg-color: #e5e5e5;
    --app-modal-buy-color: #3f9142;
    --app-modal-sell-color: #e66a6a;

    // Form
    --app-form-label-text-color: #323f4b;

    // Button
    --app-button-create-bg-color: #75e6a8;
    --app-button-cancel-bg-color: #ba2525;
    --app-button-disabled-bg-color: #d9d9d9;

    --app-button-font-size: 14px;

    // Table
    --app-sorted-column: #353f63;

    // Form
    --app-form-background: #1f2933;
}
