.DBModal {
    background-color: var(--app-modal-background-color);
    border-radius: 5px;
    overflow: auto;
    padding: 0px;
    transition: width 0.1s ease-in-out;
    .ant-modal-content {
        background-color: var(--app-modal-background-color);
        .ant-modal-header {
            background-color: var(--app-modal-background-color);
            border: none;
        }
        .ant-modal-body {
            padding: 0;
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;