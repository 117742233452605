.PhoneInput {
    display: flex;
    .PhoneInputCountry,
    .PhoneInputInput {
        background-color: var(--app-modal-background-color);
        border: none;
        height: 100%;
    }
    .focus-visible {
        outline: none;
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;