.DBPopover {
  .ant-popover-content {
    .ant-popover-arrow {
      .ant-popover-arrow-content {
        background-color: var(--app-modal-background-color);
      }
    }
    .ant-popover-inner {
      .ant-popover-inner-content {
        border-radius: 5px;
        background-color: var(--app-modal-background-color);
        .ant-menu-item {
          color: var(--app-text-black);
          background-color: inherit;
        }
        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
          border: none;
          color: var(--app-popup-text-color);
          background-color: inherit;
        }
      }
    }
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;