.ModalTextField {
    margin: 1rem 0;
    .label {
        color: var(--app-modal-label-text-color);
        font-size: 14px;
    }
    .description {
        font-size: 16px;
        a {
            color: var(--app-modal-description-color);
        }
    }
    .underline {
        text-decoration: underline;
    }
}

.ant-divider-horizontal {
    margin: 1rem 0;
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;