.VerificationEmailMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10rem;
    background-color: var(--app-primary-color);
    color: var(--app-text-color);
    border-radius: 10px;
    position: relative;
    h1 {
        color: var(--app-secondary-color);
        margin: 0;
    }
    .description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 2rem;
        .link {
            color: var(--app-secondary-color);
            cursor: pointer;
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;