.DBTag {
    border: none;
    border-radius: 15px;
    padding: 2px 6px;
    white-space: nowrap;
    margin-bottom: 0.1rem;
    &.truncate {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.approved {
        color: #ffffff;
        background-color: #3f9142;
    }
    &.cancelled {
        color: #ffffff;
        background-color: #e66a6a;
    }
    &.future {
        color: #000000;
        background-color: #fadb5f;
    }
    &.dark {
        color: #000000;
        background-color: #ffffff;
    }
    &.light {
        color: #ffffff;
        background-color: #000000;
    }
    &.grey {
        color: #ffffff;
        background-color: #3a3f47;
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;