.DBSelectLight {
    .ant-select-selector {
        background-color: white !important;
        padding: 0.5rem !important;
        font-size: 16px !important;
        min-height: 48px !important;
    }
    .ant-select-clear {
        background-color: var(--app-modal-background-color);
    }
}

.DBSelectDropdown {
    .rc-virtual-list {
        .rc-virtual-list-holder {
            .rc-virtual-list-holder-inner {
                .ant-select-item {
                    color: var(--app-text-color);
                }
                .ant-select-item-option-selected {
                    color: var(--app-text-black);
                }
                .ant-select-item-option-active {
                    color: var(--app-text-black);
                }
            }
        }
    }
}

.DBSelectDropdownLight {
    background-color: var(--app-modal-background-color);
    .rc-virtual-list {
        .rc-virtual-list-holder {
            .rc-virtual-list-holder-inner {
                .ant-select-item-option {
                    background-color: var(--app-modal-background-color);
                    font-size: 16px;
                }
                .ant-select-item-option-active {
                    background-color: var(--app-primary-color);
                    font-size: 16px;
                    color: var(--app-text-color);
                }
            }
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;