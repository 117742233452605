.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--app-primary-color);
  .title {
    color: var(--app-button-cancel-bg-color);
    font-size: 3.5rem;
  }
  .description {
    color: var(--app-text-color);
    font-size: 1.4rem;
  }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;