.PendingRequestsTable {
    .ant-table-cell {
        .trade-buy {
            color: #75e6a8;
            text-transform: capitalize;
        }
        .trade-sell {
            color: #e66a6a;
            text-transform: capitalize;
        }
    }
    .actionButtons {
        .reviewButton {
            color: var(--app-text-color);
        }
    }
    .ant-spin-nested-loading {
        .ant-spin-container {
            .ant-empty {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 570px;
                .ant-empty-description {
                    color: var(--app-text-color);
                }
            }
            .ant-pagination.ant-table-pagination {
                color: var(--app-pagination-text-color);
                .ant-pagination-item > a {
                    color: inherit;
                }
                .ant-pagination-item-ellipsis {
                    color: var(--app-pagination-text-color);
                }
                .ant-pagination-item-link {
                    color: var(--app-pagination-text-color);
                }
            }
        }
    }
}

.PendingRequestsTableEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5rem;
    .empty-request-table-image {
        width: 550px;
        margin-bottom: 2rem;
    }
    .ant-empty-description {
        color: var(--app-text-color);
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;