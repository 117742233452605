@import '~antd/dist/antd.less';
@import '/src/themes/default-theme-variables.less';

html,
body {
    font-size: 16px;
    height: auto;
    font-family: var(--app-font-family);
    font-size: var(--app-main-text-font-size);
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;