.Sidebar {
    min-height: 100vh;
    background-color: var(--app-primary-color);
    .DBLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
        .DBImage {
            height: 50%;
            width: 160px;
        }
    }

    .ant-menu-item {
        margin-top: 0.6rem;
    }

    .buttonsContainer {
        position: absolute;
        bottom: 1rem;
        padding: 0 1rem;
        width: 100%;
        .DBButton {
            padding: 1.5rem 1rem;
            font-size: var(--app-main-text-font-size);
        }
        button {
            margin-bottom: 0.8rem;
            display: flex;
            align-items: center;
            border: none;
            background-color: var(--app-background-color);
            border-radius: 10px;
            &:hover {
                color: var(--app-text-color);
            }
            span {
                font-size: var(--app-main-text-font-size);
            }
        }
    }

    .ant-menu-dark .ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item-selected > span > a,
    .ant-menu-dark .ant-menu-item-selected > a:hover,
    .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: var(--app-primary-color);
        font-size: var(--app-main-text-font-size);
    }

    .ant-layout-sider-children {
        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
            background-color: var(--app-primary-color);
            font-size: var(--app-main-text-font-size);
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;