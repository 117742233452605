.AddParticipantModal {
    .tab {
        background-color: #f7fafc;
        width: 182px;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: unset; // override button styles

        // Text
        font-size: 20px;
        line-height: 1.33;
        font-weight: 600;

        &:hover {
            cursor: pointer;
            transition: all 0.2s ease;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        }

        & > svg {
            width: 70px;
            height: 70px;
            display: inline-block;
            color: #cbd5e0;
        }
    }

    .ant-collapse {
        padding-left: 0px;
        padding-right: 0px;
        background-color: #f7fafc;

        .ant-collapse-header {
            color: #1f2933;
            background-color: #f7fafc;
            border-radius: 6px;
            padding-left: 0px;
            padding-right: 0px;
        }

        .ant-collapse-content {
            background-color: #f7fafc;

            .ant-collapse-content-box {
                background-color: var(--app-modal-background-color);
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .DBTag {
            margin-right: 24px
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;