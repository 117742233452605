.DBTab {
    background-color: var(--app-primary-color);
    padding: 0.3rem 1rem;
    .ant-tabs-tab {
        color: var(--app-text-color);
        font-size: var(--app-main-text-font-size);
    }

    .ant-select-selector {
        background-color: white !important;
    }

    .tab-badge {
        margin-left: 5px;
    }

    .ant-badge.ant-badge-not-a-wrapper.tab-badge {
        .ant-badge-count {
            background-color: #75e6a8;
        }
    }
    .tabExtraContent {
        display: flex;
        justify-content: center;
        align-items: center;
        .DBButton {
            width: 100%;
            height: 100%;
            border: none;
            color: var(--app-text-color);
            font-size: var(--app-button-font-size);
            border: 1px solid var(--app-secondary-text-color);
            &:focus {
                color: var(--app-text-color);
            }
        }
        .extraContentAutocomplete {
            margin-right: 1rem;
        }
    }
}

@primary-color: #75e6a8;@secondary-color: #1f2933;@text-color: #1f2933;@text-color-secondary: #1f2933;@background-color-light: #262d46;@component-background: #262d46;@border-color-split: #685562;@heading-color: #aea4ab;@table-selected-row-bg: transparent;